export const environment = {
    production: false,
    appVersion: '',
    envName: 'int',
    chatEndpoint: '',
    participantServiceEndpoint: 'https://participant.connect.ca-central-1.amazonaws.com',
    validateToken : 'https://aepkfuebcb.execute-api.ca-central-1.amazonaws.com/int/validateToken',
    updateAttributes:'https://aepkfuebcb.execute-api.ca-central-1.amazonaws.com/int/updateContactAttributes',
    ccpUrl : 'https://ac-connect-ae-ca-central-1-int.my.connect.aws/ccp-v2',
    apiKey: 'Emnjwr1eal4nTvgMmt1FS9lZxcudb85M4naOZGXm',
    translation: 'https://j19cmwm983-vpce-0ccc688055be9724e.execute-api.ca-central-1.amazonaws.com/ac-int/'
};
