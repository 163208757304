import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebsocketService } from '../websocket.service';
import { formatDate } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { CommonService } from '../common.service';
import { ChatService } from '../chat.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkExpiredPopupComponent } from '../link-expired-popup/link-expired-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSnackbarService } from "../notification-snackbar.service";
interface Message {
  type: 'sent' | 'received';
  sender: string;
  content: string;
  time: Date;
}
@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrl: './chat-screen.component.scss'
})
export class ChatScreenComponent {

  private participantId: string;
  private contactId: string;

  popupOpen = true;
  transcript: string;
  displayName = '';
  mobileNumber = '';
  membershipNumber = '';
  chatEnded = false;
  isTyping = false;
  isTypingEventInProgress = false;
  chatContainer = false;
  newMessage = '';
  participantToken: string;
  recordingLanguageCode: string = 'en-US';
  private _connectionToken: any;
  selectedFile: File | null = null;
  filePreview: string | ArrayBuffer | null = null;
  lastTypingEventTime: number = 0;
  isDialogVisible = false;
  dialogTitle = "";
  dialogMessage = "";
  confirmBtnText = "Confirm";
  cancelBtnText = "Cancel";
  btnColor = "blue";
  currentOperation: string = "";
  isTextSelected: boolean = false;
  placeholderTextAddLink: string = 'Enter Link';
  isPlaceholderVisible: boolean = true;
  isPlaceholderVisibleAddLink: boolean = true;
  isAddLinkDivVisible: boolean = false;
  enteredAddLink: string = '';
  insertUnorderedListSelected: boolean = false;
  isConfirmationPopUpOpen: boolean = false;

  placeholderText: string = 'Write a message';

  @ViewChild('textFormattingBar') textFormattingBar!: ElementRef;
  @ViewChild('addLinkDiv') addLinkDiv!: ElementRef;
  @ViewChild('addLinkContainer') addLinkContainer!: ElementRef;
  @ViewChild("messageInputBox") messageInputBox!: ElementRef;


  @ViewChild('endOfChat') endOfChat!: ElementRef;
  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    public chatService: ChatService,
    public commonService: CommonService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private toaster: NotificationSnackbarService,
  ) {
    this.participantId = '';
    this.contactId = '';
    this.participantToken = '';
  }

  ngOnInit(): void {
    this._connectionToken = this.commonService.connectionToken;
    this.contactId = this.commonService.ContactId;
    this.participantId = this.commonService.ParticipantId;
    this.participantToken = this.commonService.ParticipantToken;
    if (this.participantToken != '') {

      this.chatService.setUpChatSession(this.contactId, this.participantId, this.participantToken, '')
        .then(() => {
          this.chatService.getTranscript();
          this.chatContainer = true;
          this.popupOpen = false;
        })
        .catch(error => {
          this.chatContainer = false;
          this.popupOpen = true;
          this.linkExpired();
        });

    }
    this.loadChatWidget();
    this.chatService.onMessageEmitter.subscribe((contactId: any) => {
      this.scrollToBottom();
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {

    const addLinkContainer = this.elementRef.nativeElement.querySelector('.add-link-input');

    if (addLinkContainer && !addLinkContainer.contains(event.target as Node)) {
      if (this.isAddLinkDivVisible) {
        this.addLinkContainer.nativeElement.style.display = 'none';
        this.isAddLinkDivVisible = false;
      }
    }

    setTimeout(() => {
      this.showTextFormattingBar();
    }, 200);

  }

  loadChatWidget() {
    this.chatContainer = true
  }
  linkExpired() {
    this.dialog.open(LinkExpiredPopupComponent, {
      width: '599px',
      panelClass: 'link-expired-popup',
      disableClose: true,
    });
    localStorage.removeItem('chatToken');
  }
  private closeOnError(): void {
  }

  letsChat(): void {
    this.chatEnded = false;
    this.popupOpen = true;
    this.chatContainer = false;
  }
  disableRightClick(event: MouseEvent) {
    event.preventDefault();
  }

  terminateChat() {
    this.chatService.endCall(this.contactId);
    this.chatService.chatTerminated = true;
    this.isDialogVisible = false;
    localStorage.removeItem('chatToken');
    this.isConfirmationPopUpOpen = false;
    localStorage.setItem('contactId', '');
    localStorage.setItem('participantId', '');
    localStorage.setItem('participantToken', '');
  }
  handleCancel() {
    this.isDialogVisible = false;
  }

  onInput(event: any) {
    //console.log(" showTextFormattingBar onInput called");
    this.onTyping();
    this.showTextFormattingBar();
    const inputElement = event.target as HTMLElement;
    const inputBox = event.target;
    this.isPlaceholderVisible = inputElement.textContent === '';
    this.newMessage = (event.target as HTMLDivElement).innerHTML;
    //this.showTextFormattingBar()

    this.togglePlaceholder(inputBox);
  }

  removePlaceholder(event: any): void {
    const inputBox = event.target;
    // inputBox.removeAttribute('data-placeholder');
  }

  showPlaceholder(event: any): void {
    const inputBox = event.target;
    inputBox.setAttribute('data-placeholder', this.translateService.instant('customerChat.writeAMessage'));
  }

  togglePlaceholder(inputBox: any): void {
    const hasContent = inputBox.textContent.trim().length > 0;
    if (hasContent) {
      inputBox.removeAttribute('data-placeholder');
    } else {
      inputBox.setAttribute('data-placeholder', this.translateService.instant('customerChat.writeAMessage'));
    }
  }
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter'  && this.insertUnorderedListSelected) {
      
    }else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage()
    }
  }

  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const items = clipboardData?.items;

    const plainText = clipboardData?.getData('text/plain'); 
    if (plainText) {
      event.preventDefault(); // Prevent default paste behavior (to remove formatting)
      document.execCommand('insertText', false, plainText);
    }

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            this.selectedFile = file;
            this.previewFile(file);
            event.preventDefault(); // Prevent default paste behavior
            break;
          }
        }
      }
    }
  }

  handlePasteAddLink(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const items = clipboardData?.items;

    const plainText = clipboardData?.getData('text/plain'); 

    if (plainText) {
      event.preventDefault(); // Prevent default paste behavior (to remove formatting)
      document.execCommand('insertText', false, plainText);
    }

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            event.preventDefault(); // Prevent default paste behavior
            break;
          }
        }
      }
    }
  }
  sendMessage() {
    this.messageInputBox.nativeElement.innerHTML = "";
    this.insertUnorderedListSelected = false
    if (this.newMessage.trim()) {
      this.chatService.sendMessage(this.newMessage);
      this.newMessage = '';
    }

    if (this.selectedFile) {
      const uploadingMessage = {
        Type: 'ATTACHMENT',
        Attachments: [{
          AttachmentName: this.selectedFile.name,
          ContentType: this.selectedFile.type,
          AttachmentId: null
        }],
        ParticipantRole: 'CUSTOMER',
        uploading: true
      };
      // let tempSelectedFile = this.selectedFile;
      // this.clearFileSelection();
      this.chatService.transcript.push(uploadingMessage);
      this.uploadFile();
      this.selectedFile = null;
      this.filePreview = null;
    }
  }
  onTyping() {
    const currentTime = Date.now();
    if (currentTime - this.lastTypingEventTime > 2000) {
      this.chatService.sendTypingEvent();
      this.lastTypingEventTime = currentTime;
    }
  }

  uploadFile() {
    if (this.selectedFile) {
      this.chatService.sendAttachment(this.selectedFile);
    }
  }

  triggerFileInput() {
    this.fileUpload.nativeElement.click();
  }

  handleFileInput(event: any) {
    if(event.target?.files[0]?.size > 5000000){
      console.log("Shailesh Event: Size is too high", event.target.files[0].size)
      this.toaster.showWarning(this.translateService.instant(
        "customerChat.fileSize"
      ));
      const input = event.target as HTMLInputElement;
      input.value = '';
      return;
    } else{
      console.log("Shailesh Event: Size is OK", event.target.files[0].size)
    }
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const acceptedTypes = [
        'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/heic', 'image/jpeg', 'image/jfif', 'video/quicktime', 'video/mp4',
        'application/pdf', 'image/png', 'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/rtf', 'text/plain', 'audio/wav',  'text/rtf', '.rtf',
        'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];

      const parts = file.name.split('.');
      let extention = ''
      if(parts.length > 1 && parts[parts.length - 1]){
        extention = parts[parts.length - 1];
      }

      if (acceptedTypes.includes(file.type) || extention.toLowerCase() == 'heic') {
        this.selectedFile = file;
        this.previewFile(this.selectedFile);
      } else {
        const input = event.target as HTMLInputElement;
        input.value = '';
        this.toaster.showWarning(this.translateService.instant(
          "customerChat.unsupported"
        ));
      }
    }
  }

  previewFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.filePreview = reader.result;
    };
    reader.readAsDataURL(file);
  }

  removeSelectedFile() {
    this.selectedFile = null;
    this.filePreview = null;
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  openConfirmationPopup(){
    this.isConfirmationPopUpOpen = true;
  }
  closeConfirmationPopup(){
    this.isConfirmationPopUpOpen = false;
  }
  disconnectChat() {
    this.btnColor = "red";
    this.isDialogVisible = true;
    this.currentOperation = "endChat";
  }

  sanitizeHtml(content: string | undefined): SafeHtml {
    if (!content) {
      return '';
    }

    // Convert plain URLs (like www.google.com or http://example.com) into clickable links
    const urlRegex = /((https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(\S+)?)/gi;
    const contentWithLinks = content.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.startsWith('http')) {
        hyperlink = 'http://' + hyperlink;
      }
      return `<a href="${hyperlink}" target="_blank">${url}</a>`;
    });

    // Parse HTML and ensure existing links have target="_blank"
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentWithLinks, 'text/html');
    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });
    const updatedContent = doc.body.innerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(updatedContent);
  }

  scrollToBottom(): void {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 25);
  }

  async downloadAttachment(id: string, fileName: string) {
    await this.chatService.getAttachment(id, fileName);
  }


  showTextFormattingBar() {
    //console.log("showTextFormattingBar called");

    const selection = window.getSelection();
    const selectedText = selection?.toString();
    this.isTextSelected = !!selectedText?.length;

    if (this.isTextSelected) {
      const selectedNode = selection?.anchorNode;
      if (this.messageInputBox.nativeElement.contains(selectedNode)) {
        const selection = window.getSelection();
        if (selection && !this.isAddLinkDivVisible) {
          const range = selection.getRangeAt(0).getBoundingClientRect();
          if (this.textFormattingBar && this.textFormattingBar.nativeElement) {
            const bar = this.textFormattingBar.nativeElement;
            bar.style.display = 'none';   // TODO: make it flex when you want to enable text formatting

            bar.style.top = `${range.top - bar.offsetHeight - 14}px`;
            bar.style.left = `${range.left}px`;
          } else {
            //console.log("showTextFormattingBar is null")
          }
        }
      }else{
        if (this.textFormattingBar && this.textFormattingBar.nativeElement) {
          this.textFormattingBar.nativeElement.style.display = 'none';
        } else {
          //console.log("showTextFormattingBar is null in else")
        }
      }
    } else {
      if (this.textFormattingBar && this.textFormattingBar.nativeElement) {
        this.textFormattingBar.nativeElement.style.display = 'none';
      } else {
        //console.log("showTextFormattingBar is null in else")
      }
    }
  }

  formatText(command: string) {
    document.execCommand(command, false);
    this.textFormattingBar.nativeElement.style.display = 'none';
    if (command === 'insertUnorderedList') {
      this.insertUnorderedListSelected = true
    }
  }

  private savedRange: Range | null = null;

  formatLink() {
    this.textFormattingBar.nativeElement.style.display = 'none';

    const selection = window.getSelection();
    if (selection) {
      if (selection.rangeCount > 0) {
        this.savedRange = selection.getRangeAt(0);
      }
      const range = selection.getRangeAt(0).getBoundingClientRect();
      if (this.addLinkContainer && this.addLinkContainer.nativeElement) {
        const bar = this.addLinkContainer.nativeElement;
        bar.style.display = 'flex';
        bar.style.top = `${range.top - bar.offsetHeight - 30}px`;
        bar.style.left = `${range.left}px`;
        setTimeout(() => {
          this.isAddLinkDivVisible = true;
        }, 200);
        this.addLinkContainer.nativeElement.style.display = 'flex';
      } else {
        console.log("addLinkContainer is null");
      }
    }
  }

  onInputAddLink(event: any) {
    console.log("onInputAddLink called", event.target.innerHTML)
    const inputElement = event.target as HTMLElement;
    const inputBox = event.target;
    this.isPlaceholderVisibleAddLink = inputElement.textContent === '';
    this.enteredAddLink = (event.target as HTMLDivElement).innerHTML;
  }

  onApplyClick() {
    console.log("onApplyClick called", this.enteredAddLink);
    this.addLinkContainer.nativeElement.style.display = 'none';
    this.isAddLinkDivVisible = false;

    if (this.enteredAddLink && this.savedRange) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(this.savedRange);
        document.execCommand('createLink', false, this.enteredAddLink);
      }
    }
    this.enteredAddLink = '';
    this.addLinkDiv.nativeElement.innerHTML = '';
    this.savedRange = null;
  }
  
  removePlaceholderAddLink(event: any): void {
    const inputBox = event.target;
    // inputBox.removeAttribute('data-placeholder');
  }

  showPlaceholderAddLink(event: any): void {
    const inputBox = event.target;
    inputBox.setAttribute('data-placeholder', this.placeholderTextAddLink);
  }

  togglePlaceholderAddLink(inputBox: any): void {
    const hasContent = inputBox.textContent.trim().length > 0;
    if (hasContent) {
      inputBox.removeAttribute('data-placeholder');
    } else {
      inputBox.setAttribute('data-placeholder', this.placeholderTextAddLink);
    }
  }

  

}
